<template>
  <div id="top" class="hero-section wf-section">
    <div class="header-social-block">
      <div class="container-content w-container">
        <div class="header-social-txt">OFFICIAL WEBSITE OF WARDEN</div>
        <a
          href="https://github.com/WardenSwap"
          target="_blank"
          class="social-link-block w-inline-block"
          ><img
            src="/images/social_icon_github_white.svg"
            loading="lazy"
            alt="Github"
            class="social-img"
        /></a>
        <a
          href="https://t.me/wardenfi"
          target="_blank"
          class="social-link-block w-inline-block"
          ><img
            src="/images/social_icon_tg_white.svg"
            loading="lazy"
            alt="Telegram"
            class="social-img"
        /></a>
        <a
          href="https://medium.com/wardenofficial"
          target="_blank"
          class="social-link-block w-inline-block"
          ><img
            src="/images/social_icon_medium.svg"
            loading="lazy"
            alt="Medium"
            class="social-img"
        /></a>
        <a
          href="https://twitter.com/WardenSwap"
          target="_blank"
          class="social-link-block w-inline-block"
          ><img
            src="/images/social_icon_twitter.svg"
            loading="lazy"
            alt="Twitter"
            class="social-img"
        /></a>
        <a
          href="https://www.facebook.com/wardenswap"
          target="_blank"
          class="social-link-block w-inline-block"
          ><img
            src="/images/social_icon_fb.svg"
            loading="lazy"
            alt="Facebook Page"
            class="social-img"
        /></a>
        <a
          href="https://discord.com/invite/NdBymRxmxa"
          target="_blank"
          class="social-link-block w-inline-block"
          ><img
            src="/images/social_icon_discord.svg"
            loading="lazy"
            alt="Discord"
            class="social-img"
        /></a>
        <a
          href="https://github.com/valixconsulting/audit-reports/blob/main/ValixConsulting-Audit-Report-WardenFinance-Aegis-and-AegisL2-v1.0.pdf"
          target="_blank"
          class="social-link-block w-inline-block"
          ><img
            src="/images/social_icon_valix.svg"
            loading="lazy"
            alt="Valix"
            class="social-img"
        /></a>
        <a
          href="https://www.certik.com/projects/wardenswap"
          target="_blank"
          class="social-link-block w-inline-block"
          ><img
            src="/images/social_icon_certik.svg"
            loading="lazy"
            alt="Certik"
            class="social-img"
        /></a>
        <a
          href="https://www.coingecko.com/en/coins/warden"
          target="_blank"
          class="social-link-block w-inline-block"
          ><img
            src="/images/social_icon_coingekko.svg"
            loading="lazy"
            alt="Coingekko"
            class="social-img"
        /></a>
        <a
          href="https://coinmarketcap.com/currencies/wardenswap/"
          target="_blank"
          class="social-link-block w-inline-block"
          ><img
            src="/images/social_icon_cmc.svg"
            loading="lazy"
            alt="Coinmaketcap"
            class="social-img"
        /></a>
      </div>
    </div>
    <div class="container-content w-container">
      <img
        src="/images/WARDEN-LOGO-HEADER.svg"
        loading="lazy"
        alt="WARDEN SWAP"
        class="_w-logo-header"
      />
      <a
        href="https://www.wardenswap.finance/"
        target="_blank"
        class="btn-launch-app w-inline-block"
      >
        <div class="text-block-2">Launch App</div>
      </a>
      <div v-if="price" class="wad-price-txt">${{ price }}</div>
      <div v-else class="skeleton loading" />
      <img
        src="/images/WAD_chain_bsc.svg"
        loading="lazy"
        alt=""
        class="wad-price-img"
      />
    </div>
    <div class="container-content hero w-container">
      <h1 class="hero-h1-txt">
        You swap,
        <span class="topic-txt-gradient header">AI does the rest.</span>
      </h1>
      <div class="hero-motto-txt">
        The First best-rate AI swap with the lowest gas fees on multi-chains
      </div>
      <div class="hero-shownetwork-block w-clearfix">
        <img
          src="/images/hero-img-bsc.svg"
          loading="lazy"
          alt=""
          class="hero-shownetwork-img"
        />
        <img
          src="/images/hero-img-matic.svg"
          loading="lazy"
          alt=""
          class="hero-shownetwork-img"
        />
        <img
          src="/images/hero-img-arbitrum.svg"
          loading="lazy"
          alt=""
          class="hero-shownetwork-img"
        />
        <img
          src="/images/hero-img-eth.svg"
          loading="lazy"
          alt=""
          class="hero-shownetwork-img"
        />
        <img src="/images/hero-img-avalanche.svg" loading="lazy" alt="" class="hero-shownetwork-img">
        <img src="/images/hero-img-optimism.svg" loading="lazy" alt="" class="hero-shownetwork-img">
      </div>
      <div class="hero-audit-title">Audited by</div>
      <div class="hero-audit-block w-clearfix">
        <img
          src="/images/hero-img-ceritk.png"
          loading="lazy"
          width="30"
          alt=""
          class="hero-audit-img certik-img"
        />
        <div class="hero-audit-txt certik-txt">CERTIK</div>
        <img
          src="/images/hero-img-valix.png"
          loading="lazy"
          width="30"
          alt=""
          class="hero-audit-img lavix-img"
        />
        <div class="hero-audit-txt valix-txt">VALIX</div>
      </div>
      <img
        src="/images/hero-img-warden.svg"
        loading="lazy"
        alt=""
        class="hero-warden-img"
      />
      <div class="hero-mockup-swap-block w-clearfix">
        <img
          src="/images/mock-up-swap.svg"
          loading="lazy"
          height=""
          width="252"
          alt=""
          class="hero-mockup-swap"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Top",

  props: {
    price: {
      type: [String, null],
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.skeleton:empty {
  width: 60px;
  height: 25px;
  display: inline-block;
  margin-top: 50px;
  margin-right: 37px;
  margin-left: 10px;
  float: right;

  cursor: progress;
  background: linear-gradient(0.25turn, transparent, #fff, transparent),
    linear-gradient(#eee, #eee);
  background-repeat: no-repeat;
  background-position: -60px 0, 0px 0px;
  animation: loading 1.5s infinite;
}

@keyframes loading {
  to {
    background-position: 60px 0, 0px 0px;
  }
}
</style>

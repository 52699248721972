<template>
  <div class="section-partner wf-section">
    <div class="container-content w-container">
      <h2 class="h2-title mobile-title">Partners</h2>
      <div class="partner-area-block">
        <div class="partner-block">
          <img
            src="/images/logo-apeboard.png"
            loading="lazy"
            width="100"
            alt="Ape Board"
            class="partner-img"
          />
        </div>
        <div class="partner-block">
          <img
            src="/images/logo-metatime.png"
            loading="lazy"
            width="100"
            alt="Metatime"
            class="partner-img"
          />
        </div>
        <div class="partner-block">
          <img
            src="/images/logo-moo-monster.png"
            loading="lazy"
            width="100"
            alt="Moo Monster"
            class="partner-img"
          />
        </div>
        <div class="partner-block">
          <img
            src="/images/logo-pay-solution.png"
            loading="lazy"
            width="100"
            alt="Pay solution"
            class="partner-img"
          />
        </div>
        <div class="partner-block">
          <img
            src="/images/logo-tofunft.png"
            loading="lazy"
            width="100"
            alt="TofuNFT"
            class="partner-img"
          />
        </div>
        <div class="partner-block">
          <img
            src="/images/logo-nftrade.png"
            loading="lazy"
            width="100"
            alt="NFTrade"
            class="partner-img"
          />
        </div>
        <div class="partner-block">
          <img
            src="/images/logo-chainlink.png"
            loading="lazy"
            width="100"
            alt="Chainlink"
            class="partner-img"
          />
        </div>
        <div class="partner-block">
          <img
            src="/images/logo-bluca.png"
            loading="lazy"
            width="100"
            alt="Bluca"
            class="partner-img"
          />
        </div>
        <div class="partner-block"><img src="/images/logo-jfin.svg" loading="lazy" width="100" alt="Jfin" class="partner-img"></div>
        <div class="partner-block"><img src="/images/logo-zexel.svg" loading="lazy" width="100" alt="Zixel by Zipmex" class="partner-img"></div>
        <div class="partner-block"><img src="/images/logo-true-virtual-world.png" loading="lazy" width="100" alt="True Virtual World" class="partner-img"></div>
        <div class="partner-block"><img src="/images/logo-the-moon-cafe.png" loading="lazy" width="100" alt="The Moon Cafe" class="partner-img"></div>
        <div class="partner-block"><img src="/images/logo-ansxer.png" loading="lazy" width="100" alt="Ansxer" class="partner-img"></div>
        <div class="partner-block"><img src="/images/logo-six-network.png" loading="lazy" width="100" alt="SIX Network" class="partner-img"></div>
        <div class="partner-block"><img src="/images/logo-forward.png" loading="lazy" width="100" alt="Forward" class="partner-img"></div>
        <div class="partner-block"><img src="/images/bb-logo-GMX.png" loading="lazy" width="100" alt="GMX" class="partner-img"></div>
        <div class="partner-block"><img src="/images/logo-aminy.png" loading="lazy" width="100" alt="Armity" class="partner-img"></div>
      </div>

      <h2 class="h2-title mobile-title">Backed by the Best</h2>
      <div class="partner-area-block">
        <div class="partner-block">
          <img
            src="/images/bb-logo-certik.png"
            loading="lazy"
            width="100"
            alt="Certik"
            class="partner-img"
          />
        </div>
        <div class="partner-block">
          <img
            src="/images/bb-logo-valix.png"
            loading="lazy"
            width="100"
            alt="Valix"
            class="partner-img"
          />
        </div>
        <div class="partner-block">
          <img
            src="/images/bb-logo-multichain.png"
            loading="lazy"
            width="100"
            alt="Multichain"
            class="partner-img"
          />
        </div>
        <div class="partner-block">
          <img
            src="/images/bb-logo-metamask.png"
            loading="lazy"
            width="100"
            alt="Metamask"
            class="partner-img"
          />
        </div>
        <div class="partner-block">
          <img
            src="/images/bb-logo-safepal.png"
            loading="lazy"
            width="100"
            alt="SafePal"
            class="partner-img"
          />
        </div>
        <div class="partner-block">
          <img
            src="/images/bb-logo-trustwallet.png"
            loading="lazy"
            width="100"
            alt="Trust Wallet"
            class="partner-img"
          />
        </div>
        <div class="partner-block">
          <img
            src="/images/bb-logo-binance-chain-wallet.png"
            loading="lazy"
            width="100"
            alt="Binance Chain Wallet"
            class="partner-img"
          />
        </div>
        <div class="partner-block">
          <img
            src="/images/bb-logo-bsc.png"
            loading="lazy"
            width="100"
            alt="Binance Smart Chain"
            class="partner-img"
          />
        </div>
        <div class="partner-block">
          <img
            src="/images/bb-logo-polygon.png"
            loading="lazy"
            width="100"
            alt="Polygon"
            class="partner-img"
          />
        </div>
        <div class="partner-block">
          <img
            src="/images/bb-logo-arbitrum.png"
            loading="lazy"
            width="100"
            alt="Arbitrum"
            class="partner-img"
          />
        </div>
        <div class="partner-block"><img src="/images/bb-logo-eth.svg" loading="lazy" width="100" alt="Ethereum" class="partner-img"></div>
        <div class="partner-block"><img src="/images/bb-logo-avalanche.png" loading="lazy" width="100" alt="Avalanche" class="partner-img"></div>
        <div class="partner-block"><img src="/images/bb-logo-optimism.png" loading="lazy" width="80" alt="Optimism" class="partner-img"></div>
        <div class="partner-block"><img src="/images/bb-logo-immunefi.svg" loading="lazy" width="100" alt="Immunefi" class="partner-img"></div>
        <div class="partner-block"><img src="/images/bb-logo-coinbase-wallet.svg" loading="lazy" width="100" alt="Coinbase Wallet" class="partner-img"></div>
      </div>

      <h2 class="h2-title mobile-title">Featured on</h2>
      <div class="partner-area-block">
        <div class="partner-block">
          <img
            src="/images/feat-logo-coinmaketcap.png"
            loading="lazy"
            width="100"
            alt="CoinMarketCap"
            class="partner-img"
          />
        </div>
        <div class="partner-block">
          <img
            src="/images/feat-logo-coingecko.png"
            loading="lazy"
            width="100"
            alt="CoinGecko"
            class="partner-img"
          />
        </div>
        <div class="partner-block">
          <img
            src="/images/feat-logo-bsc-scan.png"
            loading="lazy"
            width="100"
            alt="BscScan"
            class="partner-img"
          />
        </div>
        <div class="partner-block">
          <img
            src="/images/feat-logo-bsc-news.png"
            loading="lazy"
            width="100"
            alt="bsc.news"
            class="partner-img"
          />
        </div>
        <div class="partner-block">
          <img
            src="/images/feat-logo-debank.png"
            loading="lazy"
            width="100"
            alt="Debank"
            class="partner-img"
          />
        </div>
        <div class="partner-block">
          <img
            src="/images/feat-logo-moonlight-token.png"
            loading="lazy"
            width="100"
            alt="Moonlight Token"
            class="partner-img"
          />
        </div>
        <div class="partner-block">
          <img
            src="/images/feat-logo-defilama-don-com.png"
            loading="lazy"
            width="100"
            alt="DefiLlama.com"
            class="partner-img"
          />
        </div>
        <div class="partner-block">
          <img
            src="/images/feat-logo-tokeninsight.png"
            loading="lazy"
            width="100"
            alt="TokenInsight"
            class="partner-img"
          />
        </div>
        <div class="partner-block">
          <img
            src="/images/feat-logo-dapp-don-com.png"
            loading="lazy"
            width="100"
            alt="Dapp.com"
            class="partner-img"
          />
        </div>
        <div class="partner-block">
          <img
            src="/images/feat-logo-dappradar.png"
            loading="lazy"
            width="100"
            alt="DappRadar"
            class="partner-img"
          />
        </div>
      </div>
      <h2 class="h2-title mobile-title">Integrated with</h2>
      <div class="partner-area-block">
        <div class="partner-block">
          <img
            src="/images/integrated-logo-the-graph.png"
            loading="lazy"
            width="100"
            alt="The graph"
            class="partner-img"
          />
        </div>
        <div class="partner-block">
          <img
            src="/images/integrated-logo-bitquery.png"
            loading="lazy"
            width="100"
            alt="Bitquery"
            class="partner-img"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SectionPartners",
};
</script>

<style></style>

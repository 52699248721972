import axios from "axios";
import BigNumber from "bignumber.js";

const urlBSC =
  "https://warden-swap-exchange-stat-bsc.s3.ap-southeast-1.amazonaws.com/data.json";
const urlPolygon =
  "https://warden-swap-exchange-stat-polygon.s3.ap-southeast-1.amazonaws.com/data.json";
const urlArbitrum =
  "https://warden-swap-exchange-stat-arbitrum.s3.ap-southeast-1.amazonaws.com/data.json";

const urlEthereum =
  "https://warden-swap-exchange-stat-ethereum.s3.ap-southeast-1.amazonaws.com/data.json";

const urlOptimism = "https://warden-swap-exchange-stat-optimism.s3.ap-southeast-1.amazonaws.com/data.json";
const urlAvalanche = "https://warden-swap-exchange-stat-avalanche.s3.ap-southeast-1.amazonaws.com/data.json";

export const fetchTotalVolume = async () => {
  let totalVolumeOnBSC = axios.get(urlBSC);
  let totalVolumeOnPolygon = axios.get(urlPolygon);
  let totalVolumeOnArbitrum = axios.get(urlArbitrum);
  let totalVolumeOnEthereum = axios.get(urlEthereum);
  let totalVolumeOnOptimism = axios.get(urlOptimism);
  let totalVolumeOnAvalanche = axios.get(urlAvalanche);

  const result = await Promise.all([
    totalVolumeOnBSC,
    totalVolumeOnPolygon,
    totalVolumeOnArbitrum,
    totalVolumeOnEthereum,
    totalVolumeOnOptimism,
    totalVolumeOnAvalanche,
  ]);

  totalVolumeOnBSC = result[0].data.totalVolumeAllTime;
  totalVolumeOnPolygon = result[1].data.totalVolumeAllTime;
  totalVolumeOnArbitrum = result[2].data.totalVolumeAllTime;
  totalVolumeOnEthereum = result[3].data.totalVolumeAllTime;
  totalVolumeOnOptimism = result[4].data.totalVolumeAllTime;
  totalVolumeOnAvalanche = result[5].data.totalVolumeAllTime;

  const totalVolumeOnAllChain = new BigNumber(totalVolumeOnBSC)
    .plus(totalVolumeOnPolygon)
    .plus(totalVolumeOnArbitrum)
    .plus(totalVolumeOnEthereum)
    .plus(totalVolumeOnOptimism)
    .plus(totalVolumeOnAvalanche)
    .toString(10);

  return {
    totalVolumeOnAllChain,
    totalVolumeOnBSC,
    totalVolumeOnPolygon,
    totalVolumeOnArbitrum,
    totalVolumeOnEthereum,
    totalVolumeOnOptimism,
    totalVolumeOnAvalanche,
  };
};

<template>
  <div class="section-find-bestrate wf-section">
    <div class="container-content w-container">
      <h2 class="h2-title">
        How can Wardenswap
        <span class="topic-txt-gradient">find the best rate?</span>
      </h2>
      <div class="how-can-find-colum-block w-row">
        <div class="column-3 w-col w-col-7">
          <img
            src="/images/how-can-w-find-img.png"
            loading="lazy"
            width="661"
            sizes="(max-width: 479px) 94vw, (max-width: 767px) 88vw, (max-width: 991px) 400px, (max-width: 1919px) 53vw, 661px"
            alt=""
            srcset="
              /images/how-can-w-find-img-p-500.png   500w,
              /images/how-can-w-find-img-p-800.png   800w,
              /images/how-can-w-find-img-p-1080.png 1080w,
              /images/how-can-w-find-img.png        1322w
            "
            class="_w-find-img"
          />
        </div>
        <div class="column-2 w-col w-col-5">
          <p class="_w-description">
            WardenSwap is a one-stop gateway to all decentralized exchanges. You
            will get a much better price in an atomic on-chain transaction with
            AMM, split trading, and a deep routing system (automated arbitrage)
            by leveraging AI power in every swap.<br /><br />AMM (Automate
            Market Maker) resolves illiquid and needless expenses. WardenSwap
            boosts AMM capacity by reducing the risk of high slippage and
            impermanent loss for liquidity providers.<br /><br />Wardenswap gets
            real-time prices from multiple pools and promptly splits trades
            among routes using AI. Then, seeks to find deep routing (automatic
            arbitrage) in-between routes to make the price even better.<br />
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SectionFindBestRate",
};
</script>

<style></style>

<template>
  <div class="section-connected-dex wf-section">
    <div class="container-content connected-dex w-container">
      <h2 class="h2-title">
        Connected <span class="topic-txt-gradient">DEXs</span>
      </h2>
      <div class="connected-area-block">
        <div class="connected-block w-clearfix">
          <img
            src="/images/hero-img-bsc.svg"
            loading="lazy"
            width="40"
            alt=""
            class="connected-chain-img"
          />
          <div class="connected-chain-txt">BNB Chain</div>
          <div class="connected-line"></div>
          <div class="connected-dex-block w-clearfix">
            <img
              src="/images/pancakeswap-dex.png"
              loading="lazy"
              width="40"
              alt=""
              class="connected-dex-img"
            />
            <div class="connected-dex-name">PancakeSwap</div>
          </div>
          <div class="connected-dex-block w-clearfix">
            <img
              src="/images/mdex-dex.png"
              loading="lazy"
              width="40"
              alt=""
              class="connected-dex-img"
            />
            <div class="connected-dex-name">MDEX</div>
          </div>
          <div class="connected-dex-block w-clearfix">
            <img
              src="/images/dodo-dex.png"
              loading="lazy"
              width="40"
              alt=""
              class="connected-dex-img"
            />
            <div class="connected-dex-name">DODO</div>
          </div>
          <div class="connected-dex-block w-clearfix">
            <img
              src="/images/biswap-dex.png"
              loading="lazy"
              width="40"
              alt=""
              class="connected-dex-img"
            />
            <div class="connected-dex-name">BiSwap</div>
          </div>
          <div class="connected-dex-block w-clearfix">
            <img
              src="/images/acryptos-dex.png"
              loading="lazy"
              width="40"
              alt=""
              class="connected-dex-img"
            />
            <div class="connected-dex-name">ACryptoS</div>
          </div>
          <div class="connected-dex-block w-clearfix">
            <img
              src="/images/julswap-dex.png"
              loading="lazy"
              width="40"
              alt=""
              class="connected-dex-img"
            />
            <div class="connected-dex-name">JulSwap</div>
          </div>
          <div class="connected-dex-block w-clearfix">
            <img
              src="/images/apeswap-dex.png"
              loading="lazy"
              width="40"
              alt=""
              class="connected-dex-img"
            />
            <div class="connected-dex-name">ApeSwap</div>
          </div>
          <div class="connected-dex-block w-clearfix">
            <img
              src="/images/latteswap-dex.png"
              loading="lazy"
              width="40"
              alt=""
              class="connected-dex-img"
            />
            <div class="connected-dex-name">LatteSwap</div>
          </div>
          <div class="connected-dex-block w-clearfix">
            <img
              src="/images/bakeryswap-dex.png"
              loading="lazy"
              width="40"
              alt=""
              class="connected-dex-img"
            />
            <div class="connected-dex-name">BakerySwap</div>
          </div>
          <div class="connected-dex-block w-clearfix">
            <img
              src="/images/nerve-dex.png"
              loading="lazy"
              width="40"
              alt=""
              class="connected-dex-img"
            />
            <div class="connected-dex-name">Nerve</div>
          </div>
          <div class="connected-dex-block w-clearfix">
            <img
              src="/images/definix-dex.png"
              loading="lazy"
              width="40"
              alt=""
              class="connected-dex-img"
            />
            <div class="connected-dex-name">Definix</div>
          </div>
          <div class="connected-dex-block w-clearfix">
            <img
              src="/images/dinosaur-eggs-dex.png"
              loading="lazy"
              width="40"
              alt=""
              class="connected-dex-img"
            />
            <div class="connected-dex-name">Dinosaur</div>
          </div>
          <div class="connected-dex-block w-clearfix">
            <img
              src="/images/pantherswap-dex.png"
              loading="lazy"
              width="40"
              alt=""
              class="connected-dex-img"
            />
            <div class="connected-dex-name">PantherSwap</div>
          </div>
          <div class="connected-dex-block w-clearfix">
            <img
              src="/images/smoothy-dex.png"
              loading="lazy"
              width="40"
              alt=""
              class="connected-dex-img"
            />
            <div class="connected-dex-name">Smoothy</div>
          </div>
          <div class="connected-dex-block w-clearfix">
            <img
              src="/images/ellipsis-dex.png"
              loading="lazy"
              width="40"
              alt=""
              class="connected-dex-img"
            />
            <div class="connected-dex-name">Ellipsis</div>
          </div>
          <div class="connected-dex-block w-clearfix">
            <img
              src="/images/twindex-dex.png"
              loading="lazy"
              width="40"
              alt=""
              class="connected-dex-img"
            />
            <div class="connected-dex-name">Twindex</div>
          </div>
          <div class="connected-dex-block w-clearfix">
            <img
              src="/images/fatanimal-dex.png"
              loading="lazy"
              width="40"
              alt=""
              class="connected-dex-img"
            />
            <div class="connected-dex-name">FatAnimal</div>
          </div>
          <div class="connected-dex-block w-clearfix">
            <img
              src="/images/wault-finance-dex.png"
              loading="lazy"
              width="40"
              alt=""
              class="connected-dex-img"
            />
            <div class="connected-dex-name">Wault</div>
          </div>
          <div class="connected-dex-block w-clearfix">
            <img
              src="/images/jetswap-dex.png"
              loading="lazy"
              width="40"
              alt=""
              class="connected-dex-img"
            />
            <div class="connected-dex-name">JetSwap</div>
          </div>
          <div class="connected-dex-block w-clearfix">
            <img
              src="/images/kyber-network-dex.png"
              loading="lazy"
              width="40"
              alt=""
              class="connected-dex-img"
            />
            <div class="connected-dex-name">Kyber</div>
          </div>
          <div class="connected-dex-block w-clearfix">
            <img
              src="/images/logo-autoshark.png"
              loading="lazy"
              width="40"
              alt=""
              class="connected-dex-img"
            />
            <div class="connected-dex-name">AutoShark</div>
          </div>
          <div class="connected-dex-block w-clearfix">
            <img
              src="/images/logo-synapse.png"
              loading="lazy"
              width="40"
              alt=""
              class="connected-dex-img"
            />
            <div class="connected-dex-name">Synapse</div>
          </div>
        </div>
        <div class="connected-block w-clearfix">
          <img
            src="/images/hero-img-matic.svg"
            loading="lazy"
            width="40"
            alt=""
            class="connected-chain-img"
          />
          <div class="connected-chain-txt">Polygon</div>
          <div class="connected-line"></div>
          <div class="connected-dex-block w-clearfix">
            <img
              src="/images/aave-dex.png"
              loading="lazy"
              width="40"
              alt=""
              class="connected-dex-img"
            />
            <div class="connected-dex-name">Aave</div>
          </div>
          <div class="connected-dex-block w-clearfix">
            <img
              src="/images/polydex-dex.png"
              loading="lazy"
              width="40"
              alt=""
              class="connected-dex-img"
            />
            <div class="connected-dex-name">Polydex</div>
          </div>
          <div class="connected-dex-block w-clearfix">
            <img
              src="/images/apeswap-dex.png"
              loading="lazy"
              width="40"
              alt=""
              class="connected-dex-img"
            />
            <div class="connected-dex-name">ApeSwap</div>
          </div>
          <div class="connected-dex-block w-clearfix">
            <img
              src="/images/quickswap-dex.png"
              loading="lazy"
              width="40"
              alt=""
              class="connected-dex-img"
            />
            <div class="connected-dex-name">QuickSwap</div>
          </div>
          <div class="connected-dex-block w-clearfix">
            <img
              src="/images/balancer-dex.png"
              loading="lazy"
              width="40"
              alt=""
              class="connected-dex-img"
            />
            <div class="connected-dex-name">Balancer</div>
          </div>
          <div class="connected-dex-block w-clearfix">
            <img
              src="/images/shshiswap-dex.png"
              loading="lazy"
              width="40"
              alt=""
              class="connected-dex-img"
            />
            <div class="connected-dex-name">SushiSwap</div>
          </div>
          <div class="connected-dex-block w-clearfix">
            <img
              src="/images/beefy-dex.png"
              loading="lazy"
              width="40"
              alt=""
              class="connected-dex-img"
            />
            <div class="connected-dex-name">Beefy</div>
          </div>
          <div class="connected-dex-block w-clearfix">
            <img
              src="/images/wault-finance-dex.png"
              loading="lazy"
              width="40"
              alt=""
              class="connected-dex-img"
            />
            <div class="connected-dex-name">Wault</div>
          </div>
          <div class="connected-dex-block w-clearfix">
            <img
              src="/images/curve-dex.png"
              loading="lazy"
              width="40"
              alt=""
              class="connected-dex-img"
            />
            <div class="connected-dex-name">Curve</div>
          </div>
          <div class="connected-dex-block w-clearfix">
            <img
              src="/images/dfyn-dex.png"
              loading="lazy"
              width="40"
              alt=""
              class="connected-dex-img"
            />
            <div class="connected-dex-name">Dfyn</div>
          </div>
          <div class="connected-dex-block w-clearfix">
            <img
              src="/images/firebird-dex.png"
              loading="lazy"
              width="40"
              alt=""
              class="connected-dex-img"
            />
            <div class="connected-dex-name">Firebird</div>
          </div>
          <div class="connected-dex-block w-clearfix">
            <img
              src="/images/iron-finance-dex.png"
              loading="lazy"
              width="40"
              alt=""
              class="connected-dex-img"
            />
            <div class="connected-dex-name">IRON</div>
          </div>
          <div class="connected-dex-block w-clearfix">
            <img
              src="/images/jetswap-dex.png"
              loading="lazy"
              width="40"
              alt=""
              class="connected-dex-img"
            />
            <div class="connected-dex-name">JetSwap</div>
          </div>
          <div class="connected-dex-block w-clearfix">
            <img
              src="/images/kyber-network-dex.png"
              loading="lazy"
              width="40"
              alt=""
              class="connected-dex-img"
            />
            <div class="connected-dex-name">Kyber</div>
          </div>
          <div class="connected-dex-block w-clearfix">
            <img
              src="/images/logo-synapse.png"
              loading="lazy"
              width="40"
              alt=""
              class="connected-dex-img"
            />
            <div class="connected-dex-name">Synapse</div>
          </div>
          <div class="connected-dex-block w-clearfix">
            <img
              src="/images/dodo-dex.png"
              loading="lazy"
              width="40"
              alt=""
              class="connected-dex-img"
            />
            <div class="connected-dex-name">DODO</div>
          </div>
        </div>
        <div class="connected-block w-clearfix">
          <img
            src="/images/hero-img-eth.svg"
            loading="lazy"
            width="40"
            alt=""
            class="connected-chain-img"
          />
          <div class="connected-chain-txt">Ethereum</div>
          <div class="connected-line"></div>
          <div class="connected-dex-block w-clearfix">
            <img
              src="/images/kyber-network-dex.png"
              loading="lazy"
              width="40"
              alt=""
              class="connected-dex-img"
            />
            <div class="connected-dex-name">Kyber</div>
          </div>
          <div class="connected-dex-block w-clearfix">
            <img
              src="/images/balancer-dex.png"
              loading="lazy"
              width="40"
              alt=""
              class="connected-dex-img"
            />
            <div class="connected-dex-name">Balancer</div>
          </div>
          <div class="connected-dex-block w-clearfix">
            <img
              src="/images/curve-dex.png"
              loading="lazy"
              width="40"
              alt=""
              class="connected-dex-img"
            />
            <div class="connected-dex-name">Curve</div>
          </div>
          <div class="connected-dex-block w-clearfix">
            <img
              src="/images/uniswap-dex.png"
              loading="lazy"
              width="40"
              alt=""
              class="connected-dex-img"
            />
            <div class="connected-dex-name">UniSwap</div>
          </div>
          <div class="connected-dex-block w-clearfix">
            <img
              src="/images/shshiswap-dex.png"
              loading="lazy"
              width="40"
              alt=""
              class="connected-dex-img"
            />
            <div class="connected-dex-name">SushiSwap</div>
          </div>
          <div class="connected-dex-block w-clearfix">
            <img
              src="/images/dodo-dex.png"
              loading="lazy"
              width="40"
              alt=""
              class="connected-dex-img"
            />
            <div class="connected-dex-name">DODO</div>
          </div>
          <div class="connected-dex-block w-clearfix">
            <img
              src="/images/logo-synapse.png"
              loading="lazy"
              width="40"
              alt=""
              class="connected-dex-img"
            />
            <div class="connected-dex-name">Synapse</div>
          </div>
          <div class="connected-dex-block w-clearfix">
            <img
              src="/images/logo-shibaswap.svg"
              loading="lazy"
              width="40"
              alt=""
              class="connected-dex-img"
            />
            <div class="connected-dex-name">ShibaSwap</div>
          </div>
        </div>

        <div class="connected-block w-clearfix">
          <img
            src="/images/hero-img-arbitrum.svg"
            loading="lazy"
            width="40"
            alt=""
            class="connected-chain-img"
          />
          <div class="connected-chain-txt">Arbitrum</div>
          <div class="connected-line"></div>
          <div class="connected-dex-block w-clearfix">
            <img
              src="/images/balancer-dex.png"
              loading="lazy"
              width="40"
              alt=""
              class="connected-dex-img"
            />
            <div class="connected-dex-name">Balancer</div>
          </div>
          <div class="connected-dex-block w-clearfix">
            <img
              src="/images/shshiswap-dex.png"
              loading="lazy"
              width="40"
              alt=""
              class="connected-dex-img"
            />
            <div class="connected-dex-name">SushiSwap</div>
          </div>
          <div class="connected-dex-block w-clearfix">
            <img
              src="/images/curve-dex.png"
              loading="lazy"
              width="40"
              alt=""
              class="connected-dex-img"
            />
            <div class="connected-dex-name">Curve</div>
          </div>
          <div class="connected-dex-block w-clearfix">
            <img
              src="/images/dodo-dex.png"
              loading="lazy"
              width="40"
              alt=""
              class="connected-dex-img"
            />
            <div class="connected-dex-name">DODO</div>
          </div>
          <div class="connected-dex-block w-clearfix">
            <img
              src="/images/uniswap-dex.png"
              loading="lazy"
              width="40"
              alt=""
              class="connected-dex-img"
            />
            <div class="connected-dex-name">UniSwap</div>
          </div>
          <div class="connected-dex-block w-clearfix">
            <img 
              src="/images/gmx-dex.svg" 
              loading="lazy" 
              width="40" 
              alt="" 
              class="connected-dex-img">
            <div class="connected-dex-name">GMX</div>
          </div>
        </div>

        <div class="connected-block w-clearfix"><img src="/images/hero-img-avalanche.svg" loading="lazy" width="40" alt="" class="connected-chain-img">
          <div class="connected-chain-txt">Avalanche</div>
          <div class="connected-line"></div>
          <div class="connected-dex-block w-clearfix"><img src="/images/curve-dex.png" loading="lazy" width="40" alt="" class="connected-dex-img">
            <div class="connected-dex-name">Curve</div>
          </div>
          <div class="connected-dex-block w-clearfix"><img src="/images/shshiswap-dex.png" loading="lazy" width="40" alt="" class="connected-dex-img">
            <div class="connected-dex-name">SushiSwap</div>
          </div>
          <div class="connected-dex-block w-clearfix"><img src="/images/kyber-network-dex.png" loading="lazy" width="40" alt="" class="connected-dex-img">
            <div class="connected-dex-name">Kyber</div>
          </div>
          <div class="connected-dex-block w-clearfix"><img src="/images/gmx-dex.svg" loading="lazy" width="40" alt="" class="connected-dex-img">
            <div class="connected-dex-name">GMX</div>
          </div>
          <div class="connected-dex-block w-clearfix"><img src="/images/pangolin-dex.png" loading="lazy" width="40" alt="" class="connected-dex-img">
            <div class="connected-dex-name">Pangolin</div>
          </div>
          <div class="connected-dex-block w-clearfix"><img src="/images/OliveCash-dex.png" loading="lazy" width="40" alt="" class="connected-dex-img">
            <div class="connected-dex-name">OliveCash</div>
          </div>
          <div class="connected-dex-block w-clearfix"><img src="/images/baguette-dex.png" loading="lazy" width="40" alt="" class="connected-dex-img">
            <div class="connected-dex-name">Baguette</div>
          </div>
          <div class="connected-dex-block w-clearfix"><img src="/images/canary-dex.svg" loading="lazy" width="40" alt="" class="connected-dex-img">
            <div class="connected-dex-name">Canary</div>
          </div>
          <div class="connected-dex-block w-clearfix"><img src="/images/elk-dex.svg" loading="lazy" width="40" alt="" class="connected-dex-img">
            <div class="connected-dex-name">Elk</div>
          </div>
          <div class="connected-dex-block w-clearfix"><img src="/images/hakuSwap-dex.png" loading="lazy" width="40" alt="" class="connected-dex-img">
            <div class="connected-dex-name">HakuSwap</div>
          </div>
          <div class="connected-dex-block w-clearfix"><img src="/images/lydia-dex.png" loading="lazy" width="40" alt="" class="connected-dex-img">
            <div class="connected-dex-name">Lydia</div>
          </div>
          <div class="connected-dex-block w-clearfix"><img src="/images/thorus-dex.png" loading="lazy" width="40" alt="" class="connected-dex-img">
            <div class="connected-dex-name">Thorus</div>
          </div>
          <div class="connected-dex-block w-clearfix"><img src="/images/joe-dex.png" loading="lazy" width="40" alt="" class="connected-dex-img">
            <div class="connected-dex-name">Trader Joe</div>
          </div>
          <div class="connected-dex-block w-clearfix"><img src="/images/yetiswap-dex.png" loading="lazy" width="40" alt="" class="connected-dex-img">
            <div class="connected-dex-name">YetiSwap</div>
          </div>
          <div class="connected-dex-block w-clearfix"><img src="/images/axial-dex.png" loading="lazy" width="40" alt="" class="connected-dex-img">
            <div class="connected-dex-name">Axial</div>
          </div>
        </div>

        <div class="connected-block w-clearfix"><img src="/images/hero-img-optimism.svg" loading="lazy" width="40" alt="" class="connected-chain-img">
          <div class="connected-chain-txt">Optimism</div>
          <div class="connected-line"></div>
          <div class="connected-dex-block w-clearfix"><img src="/images/curve-dex.png" loading="lazy" width="40" alt="" class="connected-dex-img">
            <div class="connected-dex-name">Curve</div>
          </div>
          <div class="connected-dex-block w-clearfix"><img src="/images/uniswap-dex.png" loading="lazy" width="40" alt="" class="connected-dex-img">
            <div class="connected-dex-name">UniSwap</div>
          </div>
          <div class="connected-dex-block w-clearfix"><img src="/images/velodrome-dex.svg" loading="lazy" width="40" alt="" class="connected-dex-img">
            <div class="connected-dex-name">Velodrome</div>
          </div>
        </div>


      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SectionConnectedDex",
};
</script>

<style></style>

<template>
  <div class="section-why-wardenswap wf-section">
    <div class="container-content w-container">
      <h2 class="h2-title mobile-title">
        Why <span class="topic-txt-gradient">WardenSwap?</span>
      </h2>
      <p class="why-description">
        The Real-time Best Rate AI Swap. We bring the financial world to you by
        finding the very best price from all markets in one place on WardenSwap.
      </p>
      <div class="why-colum-block w-row">
        <div class="why-column w-col w-col-4">
          <img
            src="/images/why-ai-power.svg"
            loading="lazy"
            alt="AI Power"
            class="why-fea-img"
          />
          <h3 class="h3-title">AI Power</h3>
          <p class="_w-description">
            AI Power is our secret algorithm. In every swap, our Smart Contracts
            with Machine Learning can find the best rate and learn from all
            previous trading strategies. The more trading volume, the better
            price you get!
          </p>
        </div>
        <div class="why-column w-col w-col-4">
          <img
            src="/images/why-best-rate.svg"
            loading="lazy"
            alt="Best Rate"
            class="why-fea-img"
          />
          <h3 class="h3-title">Best Rate</h3>
          <p class="_w-description">
            Depth Best-Rate Full Path Search and Split-trade features will walk
            through all possible paths and return the best one for you within a
            second. Earn more in all trades with Best Rate Swap.
          </p>
        </div>
        <div class="why-column w-col w-col-4">
          <img
            src="/images/why-lowest-gas-fee.svg"
            loading="lazy"
            alt="Lowest Gas fee"
            class="why-fea-img"
          />
          <h3 class="h3-title">Lowest Gas fee</h3>
          <p class="_w-description">
            We implement gas fee optimization, so you don't have to pay
            unnecessary fees and start maximizing your trading profits.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SectionWhyWarden",
};
</script>

<style></style>

import axios from "axios";

const urlBSC =
  "https://file-utility.wardenswap.finance/tokenlists/bsc-tokenlists.json";
const urlPolygon =
  "https://file-utility.wardenswap.finance/tokenlists/polygon-tokenlists.json";
const urlArbitrum =
  "https://file-utility.wardenswap.finance/tokenlists/arbitrum-tokenlists.json";

const urlEthereum =
  "https://file-utility.wardenswap.finance/tokenlists/ethereum-tokenlists.json";

export const fetchTotalTokenListed = async () => {
  let tokensOnBSC = axios.get(urlBSC);
  let tokensOnPolygon = axios.get(urlPolygon);
  let tokensOnArbitrum = axios.get(urlArbitrum);
  let tokensOnEtheruem = axios.get(urlEthereum);

  const result = await Promise.all([
    tokensOnBSC,
    tokensOnPolygon,
    tokensOnArbitrum,
    tokensOnEtheruem,
  ]);

  tokensOnBSC = result[0].data.tokens;
  tokensOnPolygon = result[1].data.tokens;
  tokensOnArbitrum = result[2].data.tokens;
  tokensOnEtheruem = result[3].data.tokens;

  const tokenListedAmount =
    tokensOnBSC.length +
    tokensOnPolygon.length +
    tokensOnArbitrum.length +
    tokensOnEtheruem.length;

  return {
    tokenListedAmount,
    tokensOnBSC,
    tokensOnPolygon,
    tokensOnArbitrum,
    tokensOnEtheruem,
  };
};

<template>
  <div>
    <Top :price="price" />
    <StatBox
      :trading-volume="tradingVolume"
      :token-listed-amount="tokenListedAmount"
      :connected-dex-amount="connectedDexAmount"
      :user-trust-amount="userTrustAmount"
    />
    <SectionWhyWarden />
    <SectionFindBestRate />
    <SectionProductAndInvolve />
    <SectionConnectedDex />
    <!-- <SectionNews :posts="posts" /> -->
    <SectionPartners />
    <Footer />
  </div>
</template>

<script>
import BigNumber from "bignumber.js";
import Top from "./components/Top.vue";
import StatBox from "./components/StatBox.vue";
import SectionWhyWarden from "./components/SectionWhyWarden.vue";
import SectionFindBestRate from "./components/SectionFindBestRate.vue";
import SectionProductAndInvolve from "./components/SectionProductAndInvolve.vue";
import SectionConnectedDex from "./components/SectionConnectedDex.vue";
// import SectionNews from "./components/SectionNews.vue";
import SectionPartners from "./components/SectionPartners.vue";
import Footer from "./components/Footer.vue";
import { fetchTotalVolume } from "@/helpers/trading-volumes.js";
import { fetchTotalTokenListed } from "@/helpers/token-lists.js";
import { fetchWardenPrice } from "@/helpers/warden-price.js";

export default {
  name: "Home",

  components: {
    Top,
    StatBox,
    SectionWhyWarden,
    SectionFindBestRate,
    SectionProductAndInvolve,
    SectionConnectedDex,
    // SectionNews,
    SectionPartners,
    Footer,
  },

  data: () => ({
    tradingVolume: "0",
    userTrustAmount: "65,859", // TODO: no constant
    tokenListedAmount: "0",
    connectedDexAmount: "164", // TODO: no constant
    posts: [],
    price: null,
  }),

  created() {
    this.fetchData();
  },

  methods: {
    async fetchData() {
      try {
        const tradingVolumes = fetchTotalVolume();
        const tokensListed = fetchTotalTokenListed();
        // const posts = fetchPosts();
        const price = fetchWardenPrice();

        const result = await Promise.all([
          tradingVolumes,
          tokensListed,
          // posts,
          price,
        ]);

        const billion = new BigNumber(10).exponentiatedBy(9);
        this.tradingVolume = new BigNumber(result[0].totalVolumeOnAllChain)
          .dividedBy(billion)
          .toFixed(2, 4)
          .toString(10);
        this.tokenListedAmount = result[1].tokenListedAmount.toString();
        // this.posts = result[2];
        this.price = result[2];
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<template>
  <div class="stat-block">
    <div class="container-content w-container">
      <div class="stat-colum-block w-row">
        <div
          class="state-column w-col w-col-3 w-col-medium-3 w-col-small-3 w-col-tiny-6"
        >
          <div class="stat-number">
            ${{ tradingVolume | numberWithCommas }} Billion
          </div>
          <div class="stat-txt">TRADING &nbsp;VOLUME</div>
        </div>
        <div
          class="state-column w-col w-col-3 w-col-medium-3 w-col-small-3 w-col-tiny-6"
        >
          <div class="stat-number">
            {{ userTrustAmount | numberWithCommas }}
          </div>
          <div class="stat-txt">USER TRUST</div>
        </div>
        <div
          class="state-column w-col w-col-3 w-col-medium-3 w-col-small-3 w-col-tiny-6"
        >
          <div class="stat-number">
            {{ tokenListedAmount | numberWithCommas }}
          </div>
          <div class="stat-txt">TOKEN LISTED</div>
        </div>
        <div
          class="state-column w-col w-col-3 w-col-medium-3 w-col-small-3 w-col-tiny-6"
        >
          <div class="stat-number">
            {{ connectedDexAmount | numberWithCommas }}
          </div>
          <div class="stat-txt">CONNECTED DEXs</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StatBox",

  props: {
    tokenListedAmount: {
      type: String,
      default: "0",
    },
    tradingVolume: {
      type: String,
      default: "0",
    },
    userTrustAmount: {
      type: String,
      default: "0",
    },
    connectedDexAmount: {
      type: String,
      default: "0",
    },
  },

  filters: {
    numberWithCommas(x) {
      if (x !== undefined && x !== null) {
        let parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return parts.join(".");
      }
    },
  },
};
</script>

<style></style>

<template>
  <div class="section-product-and-involve wf-section">
    <div class="container-content other-product w-container">
      <h2 class="h2-title mobile-title">
        Other <span class="topic-txt-gradient">Products</span>
      </h2>
      <div class="sdk-colum-block w-row">
        <div class="column-4 w-col w-col-6">
          <img src="/images/waden-sdk-v2-img.svg" loading="lazy" alt="" class="_w-sdk-img" />
        </div>
        <div class="column w-clearfix w-col w-col-6">
          <h3 class="h3-title _w-sdk">WARDEN SDK VERSION 2</h3>
          <p class="_w-description">
            Warden SDK is a ready-to-use software development kit for all
            partners and protocols seeking the best rate service to improve
            platform performance and hit the market right away.<br /><br />
            Get profit and earn % share profit from trading fee now!<br />
          </p>
          <div class="h3-title support-chain">Supported Chain</div>
          <div class="hero-shownetwork-block sdk_supportchain w-clearfix"><img src="images/sdk-hero-img-eth.svg"
              loading="lazy" alt="" class="hero-shownetwork-img sdk_support"><img src="images/sdk-hero-img-bsc.svg"
              loading="lazy" alt="" class="hero-shownetwork-img sdk_support"><img src="images/sdk-hero-img-matic.svg"
              loading="lazy" alt="" class="hero-shownetwork-img sdk_support"><img
              src="images/sdk-hero-img-arbitrum.svg" loading="lazy" alt=""
              class="hero-shownetwork-img sdk_support"><img src="images/sdk-hero-img-avalanche.svg" loading="lazy"
              alt="" class="hero-shownetwork-img sdk_support"><img src="images/sdk-hero-img-optimism.svg" loading="lazy"
              alt="" class="hero-shownetwork-img sdk_support"></div>
          <a href="https://docs.wardenswap.finance/warden/wardenswap-sdk/overview" target="_blank"
            class="_w-btn _w-sdk w-inline-block">
            <div>Learn More<br /></div>
          </a>
        </div>
      </div>
      <p class="_w-description">WardenSwap SDK have 3 products currently available, If you wish to use SDKs while
        receiving the %fee return from users who swap through WardenSwap SDK in your platform, please apply through
        the form below.<br></p>
      <div class="column sdk-colum-block w-row">
        <div class="w-col w-col-6"><img src="images/img_javascript_SDK.svg" loading="lazy" alt=""
            class="_w-sdk-javascript"></div>
        <div class="w-col w-col-6">
          <div class="_w-sdk-title-block">
            <div class="_w-sdk-title">WardenSwap JavaScript SDK</div>
          </div>
          <p class="_w-description">You can integrate WardenSwap SDK to help get the quote, then send the swap
            transaction with normal Web3.js or Ethers.js. So for now the key functionality of the SDK is to get you
            the best rate quote.<br></p>
        </div>
      </div>
      <div class="sdk-colum-block w-row">
        <div class="w-col w-col-6">
          <div class="_w-sdk-title-block">
            <div class="_w-sdk-title">WardenSwap UI Kit</div>
          </div>
          <p class="_w-description">In several cases, you may want to provide your users the best rate swap with the
            simple experience, but you don’t want to a lot of time building and integrating swap from
            scratch.<br><br>In the Web3 world, we all want to move fast. So our team provides you the ready-to-use UI
            kit to integrate WardenSwap UI into your website in just a minute!<br></p>
        </div>
        <div class="w-col w-col-6"><img src="images/img_ui_kit.svg" loading="lazy" alt="" class="_w-sdk-javascript">
        </div>
      </div>
      <div class="sdk-colum-block w-row">
        <div class="w-col w-col-6"><img src="images/img_referral_link.svg" loading="lazy" alt=""
            class="_w-sdk-javascript"></div>
        <div class="w-col w-col-6">
          <div class="_w-sdk-title-block">
            <div class="_w-sdk-title">Referral Link</div>
          </div>
          <p class="_w-description">Without any development effort, you could be our partner to bring people to our
            ecosystem and receive the fee sharing returned to your partner wallet.<br></p>
        </div>
      </div>
      <h3 class="h3-title _w-sdk">Why WardenSwap SDK?</h3>
      <p class="_w-description">WardenSwap SDK will be your best way to equip the Best Rate AI power to bring your
        platform to the next level. <br></p>
      <div class="why-colum-block w-row">
        <div class="why-column w-col w-col-4"><img src="images/img_profit_sharing.svg" loading="lazy" alt="AI Power"
            class="why-fea-img">
          <h3 class="h3-title">Enjoy Profit Sharing</h3>
          <p class="_w-description">Get % fee return in every swap users made through SDK as a partner.</p>
        </div>
        <div class="why-column w-col w-col-4"><img src="images/img_power_dexs.svg" loading="lazy" alt="Best Rate"
            class="why-fea-img">
          <h3 class="h3-title">Power by 50+ DEXs</h3>
          <p class="_w-description">Instant access to all trusted DEXs across popular chains and more are coming.</p>
        </div>
        <div class="why-column w-col w-col-4"><img src="images/img_best_rate_AI.svg" loading="lazy" alt="Lowest Gas fee"
            class="why-fea-img">
          <h3 class="h3-title">Own Your Best Rate AI Swap</h3>
          <p class="_w-description">Integrate Best Rate AI Swap for your Trading Bot, Payment, GameFi, or any DeFi
            Platforms.</p>
        </div>
      </div>
      <div class="metawaden-colum-block w-row">
        <div class="metawaden-column w-clearfix w-col w-col-6">
          <h3 class="h3-title _w-sdk">METAWARDEN NFT</h3>
          <p class="_w-description">
            MetaWarden NFT operates alongside WardenSwap&#x27;s primary product,
            best price perpetual swap, by combining the beauty of art with the
            best and most important part of deep tech. This is aimed to boost
            the circular economy alongside WardenSwap&#x27;s core product.<br />
          </p>
          <a href="https://www.wardenswap.finance/nft/" target="_blank" class="_w-btn _w-sdk w-inline-block">
            <div>Learn More<br /></div>
          </a>
        </div>
        <div class="column-5 w-col w-col-6">
          <img src="/images/metawarden-nft-img.svg" loading="lazy" alt="" class="metawarden-nft-img" />
        </div>
      </div>
    </div>
    <div class="bg-colour-block">
      <div class="container-content w-container">
        <h2 class="h2-title mobile-title">
          Get <span class="topic-txt-gradient">Involved</span>
        </h2>
        <div class="involve-colum-block w-row">
          <div class="involve-column w-col w-col-6">
            <div>
              <img src="/images/img-bug-bounty.svg" loading="lazy" alt="" class="involve-img" />
              <h3 class="h3-title">BUG BOUNTY PROGRAM</h3>
              <p class="_w-description">
                The bug bounty program is for incentivizing responsible bug
                disclosure on its smart contracts. The loss of users&#x27; funds
                and social engineering attacks are our primary preventions.
                <a href="https://docs.wardenswap.finance/warden/transparency/bug-bounty" target="_blank">Learn
                  more..</a><br />
              </p>
            </div>
            <a href="https://immunefi.com/bounty/wardenswap/" target="_blank" class="_w-btn involve w-inline-block">
              <div>Report a Bug<br /></div>
            </a>
          </div>
          <div class="involve-column w-col w-col-6">
            <div>
              <img src="/images/get-involved-partnership.svg" loading="lazy" alt="" class="involve-img" />
              <h3 class="h3-title">PARTNERSHIP</h3>
              <p class="_w-description">
                Our vision is to build a sustainable ecosystem for all mankind. We
                welcome all projects, partners, and businesses that are willing to
                make a better future on Defi Space together.<br />
              </p>
            </div>
            <a href="https://form.typeform.com/to/mIw8djQn" target="_blank" class="_w-btn involve w-inline-block">
              <div>Contact Us<br /></div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SectionProductAndInvolve",
};
</script>

<style>
</style>
